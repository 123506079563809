/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, InfoItem, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Sun Damage of the Eye"), React.createElement(_components.p, null, "Eyelid skin cancers account for 5-10% of all skin cancers"), React.createElement(_components.p, null, "At least 10% of cataract cases are attributable to UV exposure"), React.createElement(_components.p, null, "For more info, visit:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(Obl, {
    to: "https://www.skincancer.org/skin-cancer-prevention/sun-protection/eye-protection/",
    title: "more info on how to protect your eyes"
  }, "https://www.skincancer.org/skin-cancer-prevention/sun-protection/eye-protection/")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, React.createElement(Obl, {
    to: "https://www.skincancer.org/risk-factors/uv-radiation/",
    title: "more info on the risk factors of uv radiation"
  }, "UV radiation"), " (both UVA and UVB) from the sun reaches you by invisible wavelengths, producing DNA changes leading to skin cancers on the eyelids and premature aging of the delicate skin around your eyes."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "It is important to protect your eyes from UV radiation, even on cloudy days."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.strong, null, "UVA Rays:"), " Affects deeper layers of the skin and eyes."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.strong, null, "UVB Rays:"), " Most plentiful and affect the surface layers of the eyes the most."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, React.createElement(_components.strong, null, "UVC Rays:"), " The most damaging type of UV rays, but largely blocked by the ozone layer."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Macular Degeneration"), " is a condition that causes the part inside of the eye responsible for clear vision, the macula, to wear down."), React.createElement(_components.p, null, "Though age is the main cause of macular degeneration, UV light can contribute to it's negative symptoms."), React.createElement(_components.p, null, "Macular degeneration can lead to"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "blurred vision"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "missing spots in vision"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "floaters"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "even blindness"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Similar to your skin, too much UV light can damage your eyes. Sun damage can lead to immediate conditions, such as"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "sun burn to the eyes (photokeratitis)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "long term conditions, such as cataracts, cancers, and pterygium, (tissue growths on the cornea)"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "89Nc4vaXoUQ",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more about what to look for in sunglasses.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Prevention"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Wear ", React.createElement(Obl, {
    to: "https://amzn.to/3UY82d6",
    title: "Amazon Affiliate Link"
  }, "sunglasses"), " year-round whenever you are out in the sun. Sun damage to the eyes can occur any time of year."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Wear a ", React.createElement(Obl, {
    to: "https://amzn.to/3EQKuB3",
    title: "Amazon Affiliate Link"
  }, "hat with a three-inch brim and tightly woven fabric (no holes)"), " to protect your face and the top of your head. Hats can block as much as half of all UV rays from your eyes and eyelids."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Use a ", React.createElement(Obl, {
    to: "https://amzn.to/3Op4fDd",
    title: "Amazon Affiliate Link"
  }, "broad-spectrum sunscreen with an SPF of 30 or higher"), ", to protect yourself when you need to take off your shades."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Be aware of clouds: the sun's rays can pass through haze and clouds, so eye protection is important even when there is cloud cover."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Take extra care near water, snow and sand: 80 percent or more of the sun's rays reflect off of these surfaces, so they hit your eyes and skin a second time."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Be altitude-aware: UV intensity increases with altitude; protect your eyes when in higher altitudes."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Seek ", React.createElement(Obl, {
    to: "https://amzn.to/3OtBPI5",
    title: "Amazon Affiliate Link"
  }, "shade"), " during times when the sun is most intense"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "If you are experiencing problems with your eyes or eyelids, visit your physician or eye doctor."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
